/* You can add global styles to this file, and also import other style files */

/*--------------------------------------------------------------------------------------------------------------------------------------
 Importaciones de Archivo de Estilos
--------------------------------------------------------------------------------------------------------------------------------------*/

@import 'node_modules/bootstrap/dist/css/bootstrap.css';
@import './app/common/assets/styles/scss/backgrounds.scss';
@import './app/common/assets/styles/scss/materialize.scss';
@import './app/common/assets/styles/scss/text.scss';
@import './app/common/assets/styles/scss/variables.scss';
@import './app/common/assets/styles/scss/effects.scss';
@import './app/common/assets/styles/scss/themes-material.scss';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Helvetica Neue', sans-serif;
  width: 100%; /* Ajustado para ocupar todo el ancho */
  height: 100vh;
  background-image: url('./app/common/assets/images/logos/fondo-inass-2x.png');
  background-size: cover;
  background-attachment: fixed; /* Para que el fondo se ajuste al scroll */
  background-repeat: no-repeat;
  font-family: 'Dosis', sans-serif;
}
