/*--------------------------------------------------------------------------------------------------------------------------------------
 Importacion de variables
--------------------------------------------------------------------------------------------------------------------------------------*/

@import './variables.scss';
button:focus {
  outline: 0px dotted !important;
  outline-color: -webkit-focus-ring-color;
  outline-style: auto;
  outline-width: 5px;
  margin: 0;
  font-family: 'Lato';
}

.row:before,
.row:after {
  display: none !important;
}

h1 {
  font-size: 35px !important;
}

p {
  font-size: 13px !important;
  color: $v-cc-txt;
}

::-webkit-scrollbar-track {
  background-color: $v-cc-white;
}

//Aqui se oculto para no afectar el input select
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: $v-cc-primary; //Color del scrol
  border-radius: 1px;
}

/*---------------------------------------------------------------------------------------------------------------------------------
 BUTTONS
--------------------------------------------------------------------------------------------------------------------------------------*/

// Primary
.cc-btn-primary {
  background-color: $v-cc-primary !important;
  color: $v-cc-white !important;
  &:hover {
    background-color: $v-cc-primary-selected !important;
  }
}

.cc-btn-primary-outline {
  background-color: $v-cc-white !important;
  color: $v-cc-primary !important;
  border: 2px solid $v-cc-primary !important;
  &:hover {
    background-color: $v-cc-primary !important;
    color: $v-cc-white !important;
  }
}

.cc-btn-primary-disabled {
  background-color: $v-cc-primary-disabled;
  color: $v-cc-white;
}

// Secondary
.cc-btn-secondary {
  background-color: $v-cc-secondary !important;
  color: $v-cc-white !important;
  &:hover {
    background-color: $v-cc-secondary-selected !important;
  }
}

.cc-btn-secondary-outline {
  background-color: $v-cc-white;
  color: $v-cc-secondary;
  border: 2px solid $v-cc-secondary;
  &:hover {
    background-color: $v-cc-secondary;
    color: $v-cc-white;
  }
}

.cc-btn-secondary-disabled {
  background-color: $v-cc-secondary-disabled;
  color: $v-cc-white;
}

// Notificaciones
.cc-btn-success {
  background-color: $v-cc-success;
  color: $v-cc-white;
  &:hover {
    background-color: $v-cc-primary-selected;
  }
}

.cc-btn-error {
  background-color: $v-cc-error !important;
  color: $v-cc-white !important;
}

.cc-btn-error-outline {
  background-color: $v-cc-white !important;
  color: $v-cc-error !important;
  border: 2px solid $v-cc-error !important;
  &:hover {
    background-color: $v-cc-error !important;
    color: $v-cc-white !important;
  }
}

.cc-btn-warning {
  background-color: $v-cc-warning !important;
  color: $v-cc-white !important;
}

.cc-btn-warning-outline {
  background-color: $v-cc-white !important;
  color: $v-cc-warning !important;
  border: 2px solid $v-cc-warning !important;
  &:hover {
    background-color: $v-cc-warning !important;
    color: $v-cc-white !important;
  }
}

.cc-btn-info {
  background-color: $v-cc-info;
  color: $v-cc-white;
}

// Colores neutros de uso especifico
.cc-btn-white {
  background-color: $v-cc-white;
  color: $v-cc-txt;
}

.cc-btn-light-white {
  background-color: $v-cc-light-white;
  color: $v-cc-txt;
}

.cc-btn-dark-gray {
  background-color: $v-cc-dark-gray;
  color: $v-cc-white;
}

.cc-btn-light-gray {
  background-color: $v-cc-light-gray;
  color: $v-cc-white;
}

.cc-btn-medium-gray {
  background-color: $v-cc-medium-gray;
  color: $v-cc-white;
}

.cc-btn-medium-gray-outline {
  background-color: $v-cc-white !important;
  color: $v-cc-medium-gray !important;
  border: 2px solid $v-cc-medium-gray !important;
  &:hover {
    background-color: $v-cc-medium-gray !important;
    color: $v-cc-white !important;
  }
}

/*---------------------------------------------------------------------------------------------------------------------------------
END BUTTON
--------------------------------------------------------------------------------------------------------------------------------------*/

// TODO DE AQUI PARA ABAJO REVISAR BIEN Y BORRAR LOS INNECESARIO

/*--------------------------------------------------------------------------------------------------------------------------------------
 CARDS
--------------------------------------------------------------------------------------------------------------------------------------*/

.ccng-border {
  border: 1px solid $v-cc-primary;
}

.card-border {
  border-radius: 10px !important;
}

.card {
  border: none;
}

.card:focus {
  outline-color: transparent;
}

.card-content {
  font-size: 25px;
  padding: 10px;
  width: 100%;
}

.wallet-card {
  padding: 0 !important;
  overflow: hidden;
  border-radius: 10px;
  height: 100%;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-self: flex-end;
  transition: transform 0.5s;
}

.wallet-card:hover {
  transform: scale(1.05);
  cursor: pointer;
}

/*--------------------------------------------------------------------------------------------------------------------------------------
END CARDS
--------------------------------------------------------------------------------------------------------------------------------------*/

.dark-backdrop {
  background: $v-cc-secondary !important;
  opacity: 0.9 !important;
}

.overflow-text-p-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
  overflow: hidden;
}

.hiddeScrollBar::-webkit-scrollbar {
  display: none;
}

.position-absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.shadowBorder {
  box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 12%) !important;
}

/*--------------------------------------------------------------------------------------------------------------------------------------
 CLASES PARA LOS tamaños
--------------------------------------------------------------------------------------------------------------------------------------*/
.w-0 {
  width: 0% !important;
}

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

/*--------------------------------------------------------------------------------------------------------------------------------------
 END CLASES PARA LOS tamaños
--------------------------------------------------------------------------------------------------------------------------------------*/

.alertPrincipalStyle > div {
  background-color: transparent !important;
}

.sectionTable {
  border-radius: 10px;
  margin-bottom: 23px !important;
  overflow: hidden;
  padding: 0 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.scale-5 {
  transform: scale(1.5);
}
