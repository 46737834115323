/*--------------------------------------------------------------------------------------------------------------------------------------
 Se usa para los estilos de iconos
--------------------------------------------------------------------------------------------------------------------------------------*/
@import '../../../assets/styles/scss/variables.scss';

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-display: block;
  font-size: 20px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.mat_Img_Icon {
  width: 20px !important;
  height: 20px !important;
}

.mat-icon {
  width: 20px !important;
  height: 20px !important;
}

/*--------------------------------------------------------------------------------------------------------------------------------------
 Se usa para los item del menu lateral
--------------------------------------------------------------------------------------------------------------------------------------*/

.mat-menu-item-highlighted:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item:hover:not([disabled]) {
  background: $v-cc-white;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  background-color: $v-cc-white;
  border-radius: 5px;
}

.mat-expansion-panel-body {
  padding: 0 0px 16px;
}
/*--------------------------------------------------------------------------------------------------------------------------------------
 Clase para mat-expansion
--------------------------------------------------------------------------------------------------------------------------------------*/
.default_expansio_background_color {
  .mat-expansion-panel-body {
    background-color: $v-cc-light-white;
  }
}

.mat-accordion {
  text-align: start;
}

.mat-accordion .mat-expansion-panel:not(.mat-expanded),
.mat-accordion .mat-expansion-panel:not(.mat-expansion-panel-spacing) {
  border-radius: $dynamic_border_radius !important;
}

.mat-expansion-panel-header {
  border-radius: $dynamic_border_radius !important;
}

/*--------------------------------------------------------------------------------------------------------------------------------------
  INPUTS
--------------------------------------------------------------------------------------------------------------------------------------*/

.ccng-input .mat-form-field .mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-radius: 0 !important;
}

.ccng-input .mat-form-field .mat-form-field-appearance-outline .mat-form-field-outline-start {
  border-radius: 0 !important;
}

.first-input .mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-radius: 0 !important;
}

//* No entiendo por qué ponerlo gris
// .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing{
//    border-color: $v-cc-light-gray !important;
// }

.input-size {
  font-size: 13px !important;
  color: $v-cc-txt;
}

//* Así se ven más bonitos los inputs
.mat-mdc-text-field-wrapper {
  height: 47px !important;
  font-size: 13px !important;
  background-color: white;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px !important;
  font-size: 13px;
}

// Para los mensajes de error
.mat-mdc-form-field-error {
  font-size: 10px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(-26.75px) scale(var(--mat-mdc-form-field-floating-label-scale, 0.75)) !important;
  transform: var(--mat-mdc-form-field-label-transform);
  font-size: 13px;
}

.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  border-radius: var(--mdc-outlined-text-field-container-shape);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 13px !important;
  padding-bottom: 10px !important;
}

//* De esta manera el contenedor del borde de los inputs tiene el mismo border-radius
.mat-mdc-text-field-wrapper {
  border-radius: $dynamic_border_radius !important;
}

.mat-mdc-form-field-infix {
  min-height: 45px !important;
}

.mat-mdc-form-field {
  font-size: 13px;
}

.cdk-overlay-pane:not(.mat-mdc-select-panel-above) div.mat-mdc-select-panel {
  border-radius: $dynamic_border_radius !important;
}

.cdk-overlay-pane:not(.mat-mdc-autocomplete-panel-above) div.mat-mdc-autocomplete-panel {
  border-radius: $dynamic_border_radius !important;
}

.mat-datepicker-content {
  border-radius: $dynamic_border_radius !important;
}

::ng-deep .mat-focused .mat-form-field-label {
  color: $v-cc-primary !important;
}

::ng-deep.mat-form-field-ripple {
  background-color: $v-cc-primary !important;
}

::ng-deep .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: $v-cc-primary !important;
}

/*--------------------------------------------------------------------------------------------------------------------------------------
  Checkbox
--------------------------------------------------------------------------------------------------------------------------------------*/

.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background-color: $v-cc-primary !important;
}

.mdc-checkbox__background {
  border-radius: 50% !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
  background-color: $v-cc-secondary-selected-radio !important;
  border: $v-cc-primary !important;
  height: 16px;
  width: 16px;
  // padding: 10px;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  height: 70%;
  margin-top: 1.5px;
  color: $v-cc-white !important ;
}

/*--------------------------------------------------------------------------------------------------------------------------------------
  END Checkbox
--------------------------------------------------------------------------------------------------------------------------------------*/

/*--------------------------------------------------------------------------------------------------------------------------------------
  Paginator
--------------------------------------------------------------------------------------------------------------------------------------*/
.mat-mdc-paginator-container {
  color: $v-cc-txt;
  font-size: 14px;
}

.mat-mdc-paginator-page-size-select {
  .mdc-text-field--outlined .mdc-notched-outline {
    .mdc-notched-outline__leading {
      border: none !important;
    }
    .mdc-notched-outline__notch {
      border: none !important;
    }
    .mdc-notched-outline__trailing {
      border: none !important;
    }
  }
  .mat-mdc-select-trigger {
    border-bottom: 1px solid $v-cc-txt;
  }
}

.mat-mdc-select-panel-above div.mat-mdc-select-panel {
  border-radius: $dynamic_border_radius !important;
}

.mdc-menu-surface.mat-mdc-select-panel {
  padding: 0 !important;
}

@media (max-width: 375px) {
  .mat-mdc-paginator-range-actions {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-wrap: wrap !important;
    margin-top: 15px;
    .mat-mdc-paginator-range-label {
      width: 100%;
      text-align: center;
    }
  }
}
/*--------------------------------------------------------------------------------------------------------------------------------------
  END Paginator
--------------------------------------------------------------------------------------------------------------------------------------*/

/*--------------------------------------------------------------------------------------------------------------------------------------
  calendar
--------------------------------------------------------------------------------------------------------------------------------------*/

.mat-calendar-body-selected {
  background-color: $v-cc-primary !important;
}

.mat-datepicker-toggle-active {
  color: $v-cc-primary !important;
}

.mat-calendar-body-today {
  background-color: white !important;
}

.mat-calendar-body-selected {
  background-color: $v-cc-primary !important;
}

.mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field-icon-suffix {
  display: flex;
  flex-wrap: nowrap;
}

.mat-calendar-body-in-range::before {
  background-color: $v-cc-primary-disabled !important;
}

.mat-calendar-body-cell:hover {
  .mat-calendar-body-cell-content {
    background-color: $v-cc-primary-disabled !important;
  }
}

.mat-datepicker-content {
  border: 1px solid $v-cc-light-gray;
  box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 12%) !important;
}

.mat-calendar-controls {
  margin: 0 calc(4.7142857143% - 16px) 5% !important;
}

/*--------------------------------------------------------------------------------------------------------------------------------------
  END calendar
--------------------------------------------------------------------------------------------------------------------------------------*/

/*--------------------------------------------------------------------------------------------------------------------------------------
  Selects
--------------------------------------------------------------------------------------------------------------------------------------*/

.mat-select-panel.mat-primary {
  background-color: $v-cc-light-white !important;
  box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 12%) !important;
}

.mat-radio-label {
  white-space: inherit !important;
  margin-bottom: 0px !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: $v-cc-black !important;
}

.mdc-menu-surface.mat-mdc-select-panel {
  border: 1px solid $v-cc-light-gray;
  box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 12%) !important;
}
// .cdk-overlay-pane:not(.mat-mdc-autocomplete-panel-above)
.mdc-menu-surface.mat-mdc-select-panel {
  padding: 0 !important;
  border: 1px solid $v-cc-light-gray;
  box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 12%) !important;
}

// .cdk-overlay-pane:not(.mat-mdc-autocomplete-panel-above)
.mdc-menu-surface.mat-mdc-autocomplete-panel {
  padding: 0 !important;
  border: 1px solid $v-cc-light-gray;
  box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 12%) !important;
}

.mat-autocomplete-panel,
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover),
.mat-select-panel.mat-primary {
  background: $v-cc-light-white;
}

.mat-select-panel mat-option.mat-option {
  border-top: 1px solid $v-cc-black;
  height: unset;
}

.mat-select-panel.mat-option.mat-option:hover {
  background-color: $v-cc-light-white;
}

.mat-option {
  border-top: 1px solid $v-cc-white !important;
  height: auto !important;
}

.mat-option-text.mat-option-text {
  white-space: normal;
  color: $v-cc-black !important;
}

.mat-option:hover {
  background-color: $v-cc-white;
}

.mat-mdc-select-arrow {
  color: inherit !important;
}

.mat-mdc-select-value {
  color: inherit !important;
}

//*Esto hace que el panel de selección no esté tan lejos del input field
//*Comentado para no afectar directamente a todos los cdk-overlay-pane
// .cdk-overlay-pane{
//   margin-top: -23px;
// }

.overlay_pane_cdk_margin {
  margin-top: -23px;
}

//* Esto es para que haya overlap del label con el ícono de suffix
.mat-mdc-floating-label {
  // display: flex !important;
  padding-right: 24px !important;
}

/*--------------------------------------------------------------------------------------------------------------------------------------
  END Selects
--------------------------------------------------------------------------------------------------------------------------------------*/

/*--------------------------------------------------------------------------------------------------------------------------------------
 END INPUTS
--------------------------------------------------------------------------------------------------------------------------------------*/

/*--------------------------------------------------------------------------------------------------------------------------------------
 Estilo de los dialogos
--------------------------------------------------------------------------------------------------------------------------------------*/

mat-dialog-container {
  transform: none !important;
  height: fit-content !important;
  padding: 30px !important;
  overflow: hidden !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.mat-dialog-container {
  border-radius: 15px !important;
}

.mat-dialog-content {
  max-height: 50vh !important;
}
/*--------------------------------------------------------------------------------------------------------------------------------------
 END Estilo de los dialogos
--------------------------------------------------------------------------------------------------------------------------------------*/

/*--------------------------------------------------------------------------------------------------------------------------------------
 Estilo del menu
--------------------------------------------------------------------------------------------------------------------------------------*/

.mat-mdc-menu-content {
  border: 1px solid $v-cc-light-gray;
  border-radius: var(--mat-menu-container-shape);
  box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 12%) !important;
}

.mat-menu-panel {
  min-width: 160px !important;
}

.mat-menu-content {
  background-color: $v-cc-light-white;
  border: 0.5 solid $v-cc-black;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.mat-menu-item:hover:not([disabled]) {
  background-color: $v-cc-black !important;
}
/*--------------------------------------------------------------------------------------------------------------------------------------
 END Estilo del menu
--------------------------------------------------------------------------------------------------------------------------------------*/

/*--------------------------------------------------------------------------------------------------------------------------------------
 Estilo de los radios/Button
--------------------------------------------------------------------------------------------------------------------------------------*/

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $v-cc-primary;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-outer-circle {
  border-color: $v-cc-primary !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: $v-cc-primary !important;
}

.blueRadio.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $v-cc-secondary;
}

.blueRadio.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle,
.blueRadio .mat-radio-outer-circle {
  border-color: $v-cc-secondary !important;
}

.blueRadio.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: $v-cc-secondary !important;
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background-color: $v-cc-light-white !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $v-cc-primary;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-outer-circle {
  border-color: $v-cc-primary !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: $v-cc-primary !important;
}
/*--------------------------------------------------------------------------------------------------------------------------------------
 END Estilo de los radios/Button
--------------------------------------------------------------------------------------------------------------------------------------*/
