/*--------------------------------------------------------------------------------------------------------------------------------------
 BACKGROUND
--------------------------------------------------------------------------------------------------------------------------------------*/

@import './variables.scss';

/*--------------------------------------------------------------------------------------------------------------------------------------
  Environments colors
--------------------------------------------------------------------------------------------------------------------------------------*/

.cc-bg-environmentDev {
  background-color: $v-cc-error;
}

.cc-bg-environmentSandbox {
  background-color: $v-cc-primary;
}

.cc-bg-environmentQA {
  background-color: #f0df48a9;
}

.cc-bg-environmentPreProd {
  background-color: #673c7d;
}

/*--------------------------------------------------------------------------------------------------------------------------------------
   Paleta de colores del proyecto
--------------------------------------------------------------------------------------------------------------------------------------*/

// Primary
.cc-bg-primary {
  background: $v-cc-primary;
}

.cc-bg-primary-disabled {
  background: $v-cc-primary-disabled;
}

.cc-bg-primary-selected {
  background: $v-cc-primary-selected;
}

// Secondary
.cc-bg-secondary {
  background: $v-cc-secondary;
}

.cc-bg-secondary-disabled {
  background: $v-cc-secondary-disabled;
}

.cc-bg-secondary-selected {
  background: $v-cc-secondary-selected;
}

// Notificaciones
.cc-bg-success {
  background: $v-cc-success;
}

.cc-bg-success-outline {
  background: $v-cc-white;
  border: 0.1px solid $v-cc-success;
  color: $v-cc-success;
}

.cc-bg-error {
  background: $v-cc-error;
}

.cc-bg-error-outline {
  background: $v-cc-white;
  border: 0.1px solid $v-cc-error;
  color: $v-cc-error;
}

.cc-bg-warning {
  background: $v-cc-warning;
}

.cc-bg-warning-outline {
  background: $v-cc-white;
  border: 0.1px solid $v-cc-warning;
  color: $v-cc-warning;
}

.cc-bg-info {
  background: $v-cc-info;
}

.cc-bg-info-outline {
  background: $v-cc-white;
  border: 0.1px solid $v-cc-info;
  color: $v-cc-info;
}

.cc-bg-medium-gray {
  background: $v-cc-medium-gray;
}

.cc-bg-medium-gray-outline {
  background: $v-cc-white;
  border: 0.1px solid $v-cc-txt;
  color: $v-cc-txt;
}

// Fondos
.cc-bg-background-page {
  background: $v-cc-background-page;
}

.cc-bg-menu {
  background: $v-cc-menu;
}

.cc-bg-navbar {
  background: $v-cc-navbar;
}

// Colores neutros de uso especifico
.cc-bg-white {
  background: $v-cc-white;
}

.cc-bg-light-white {
  background: $v-cc-light-white;
}

.cc-bg-black {
  background: $v-cc-black;
}

.cc-bg-dark-gray {
  background: $v-cc-dark-gray;
}

.cc-bg-medium-gray {
  background: $v-cc-medium-gray;
}

.cc-bg-light-gray {
  background: $v-cc-light-gray;
}
