/*--------------------------------------------------------------------------------------------------------------------------------------
  Variables de colores
--------------------------------------------------------------------------------------------------------------------------------------*/

// Paleta de colores del proyecto
$v-cc-primary: var(--v-cc-dynamic-primary);
$v-cc-secondary-selected-radio: var(--v-cc-dynamic-secondary-selected);
$v-cc-primary-disabled: #daf2ed;
$v-cc-primary-selected: var(--v-cc-dynamic-primary-selected);
$v-cc-secondary: #6b9cf4;
$v-cc-secondary-disabled: #eff4fd;
$v-cc-secondary-selected: #4484e0;
// Notificaciones
$v-cc-success: #14c6a4;
$v-cc-error: #ff0000;
$v-cc-warning: #a09f9f;
$v-cc-info: #03a9f4;
// Textos
$v-cc-txt: #595959;
$v-cc-back-txt: #a09f9f;
$v-cc-title-txt: #afafaf;
// Fondos
$v-cc-background-page: #f1f4f8;
$v-cc-menu: #ffffff;
$v-cc-navbar: #444444;
// Colores neutros de uso especifico
$v-cc-white: #ffffff;
$v-cc-light-white: #f1f4f8;
$v-cc-black: #000000;
$v-cc-dark-gray: #595959;
$v-cc-medium-gray: #a09f9f;
$v-cc-light-gray: #cccaca;

//*Seteo este color como una variable para que pueda ser seteado de forma dinámica
$dynamic_border_radios_button: 20px;

//*Seteo este color como una variable para que pueda ser seteado de forma dinámica
$dynamic_border_radius: 10px;

//*Creo variables que puedan ser modificadas en JS
:root {
  --v-cc-dynamic-primary: #14c6a4;
  --v-cc-dynamic-primary-selected: #0db292;
  --v-cc-dynamic-secondary-selected: #ff5722;
}
